import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import { useRecordContext } from "ra-core";
import { InjectedFieldProps, PublicFieldProps } from "react-admin";
import { get } from "lodash";
import PDFIcon from "@material-ui/icons/PictureAsPdf";
import DownloadIcon from "@material-ui/icons/CloudDownload";

const useStyles = makeStyles(
  {
    anchor: {
      textDecoration: "none",
      color: "unset",
    },
    list: {
      display: "flex",
      listStyleType: "none",
    },
    image: {
      margin: "0.5rem",
      maxHeight: "10rem",
    },
    pdf: {
      margin: "0.5rem",
      height: "10rem",
      width: "10rem",
      backgroundColor: "lightgray",
      padding: "1rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      textDecoration: "none",
    },
  },
  { name: "RaExtendedImageField" }
);

export interface ExtendedImageFieldProps
  extends PublicFieldProps,
    InjectedFieldProps {
  src?: string;
  title?: string;
  classes?: object;
  source?: string;
}

const ExtendedImageField = (props: ExtendedImageFieldProps) => {
  const {
    className,
    classes: classesOverride,
    emptyText,
    source,
    src,
    title,
    ...rest
  } = props;
  const record = useRecordContext(props);
  const sourceValue = get(record, source ?? "");
  const classes = useStyles(props);
  if (!sourceValue) {
    return emptyText ? (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...rest}
      >
        {emptyText}
      </Typography>
    ) : (
      <div className={className} {...rest} />
    );
  }

  if (Array.isArray(sourceValue)) {
    return (
      <ul className={classnames(classes.list, className)} {...rest}>
        {sourceValue.map((file, index) => {
          const fileTitleValue: string = get(file, title ?? "") || title;
          const srcValue: string = get(file, src ?? "") || title;

          return (
            <li key={index}>
              <a
                href={srcValue}
                target="_blank"
                rel="noreferrer"
                download={fileTitleValue}
                className={classes.anchor}
              >
                {srcValue.toLowerCase().endsWith(".pdf") ? (
                  <div title={fileTitleValue} className={classes.pdf}>
                    <PDFIcon />
                    <div>{fileTitleValue}</div>
                  </div>
                ) : (
                  <img
                    alt={fileTitleValue}
                    title={fileTitleValue}
                    src={srcValue}
                    className={classes.image}
                  />
                )}
              </a>
            </li>
          );
        })}
      </ul>
    );
  }

  const titleValue = get(record, title ?? "") || title;

  return (
    <div className={className} {...rest}>
      {sourceValue.toLowerCase().endsWith(".pdf") ? (
        <div title={titleValue} className={classes.pdf}>
          <PDFIcon />
          <div>{titleValue}</div>
        </div>
      ) : (
        <img
          alt={titleValue}
          title={titleValue}
          src={sourceValue}
          className={classes.image}
        />
      )}
    </div>
  );
};

// What? TypeScript loses the displayName if we don't set it explicitly
ExtendedImageField.displayName = "ExtendedImageField";

ExtendedImageField.defaultProps = {
  addLabel: true,
};

export default ExtendedImageField;
