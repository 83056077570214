import React, { useState, useEffect } from "react";
import {
  Card,
  Avatar,
  Theme,
  CardMedia,
  Box,
  Typography,
  CardContent,
  Grid,
} from "@material-ui/core";
import { useGetList, Loading, Error } from "react-admin";
import { apiUrl } from "lib/dataProvider";

export const LoginKonten = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<false | Error>(false);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(
      apiUrl +
        "/konten?filter=%7B%7D&range=%5B0%2C9%5D&sort=%5B%22id%22%2C%22DESC%22%5D",
      {
        signal,
      }
    )
      .then((res) => {
        if (res.status >= 500) return Promise.reject();
        else return res;
      })
      .then((res) => res.json())
      .then(setData)
      .then(() => setLoading(false))
      .catch((e) => {
        if (e && e.name === "AbortError") {
          return;
        } else {
          setError(e);
        }
        setLoading(false);
      });

    return controller.abort;
  }, []);
  if (loading) {
    return <Loading />;
  }
  if (error !== false) {
    return <p>Error loading news.</p>;
  }
  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="div">
              Berita Terbaru
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      {data.map((entry: any) => (
        <Grid item id={entry.id} xs={12}>
          <Card>
            <CardMedia component="img" height={140} image={entry.gambar.src} />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {entry.judul}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {entry.isi}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
