import React, {
  HtmlHTMLAttributes,
  ComponentType,
  createElement,
  ReactNode,
  useRef,
  useEffect,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {
  Card,
  Avatar,
  Theme,
  CardMedia,
  Box,
  Typography,
  CardContent,
  Grid,
} from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import { StaticContext } from "react-router";
import { useHistory } from "react-router-dom";
import { useCheckAuth, TitleComponent } from "ra-core";
import { useGetList, Loading, Error } from "react-admin";

import {
  defaultTheme,
  LoginForm,
  Notification as DefaultNotification,
} from "react-admin";
import { LoginKonten } from "./LoginKonten";

export interface LoginProps
  extends Omit<HtmlHTMLAttributes<HTMLDivElement>, "title"> {
  backgroundImage?: string;
  children?: ReactNode;
  classes?: object;
  className?: string;
  notification?: ComponentType;
  staticContext?: StaticContext;
  theme?: object;
  title?: TitleComponent;
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    main: {
      //display: "flex",
      //flexDirection: "row",
      minHeight: "100vh",
      width: "100vw",
      //alignItems: "center",
      //justifyContent: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage:
        "radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)",
    },
    loginForm: {},
    card: {
      minWidth: 300,
      height: "auto",
      marginTop: "6em",
      marginBottom: "auto",
    },
    content: {
      padding: "4em",
    },
    avatar: {
      objectFit: "contain",
    },
    icon: {
      backgroundColor: (theme.palette.secondary as any)[500],
    },
  }),
  { name: "RaLogin" }
);

/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider.login()` method. Redirects to the root page (/)
 * upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */
const Login: React.FunctionComponent<LoginProps> = (props) => {
  const {
    theme,
    title,
    classes: classesOverride,
    className,
    children,
    notification,
    staticContext,
    backgroundImage,
    ...rest
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const classes = useStyles(props);
  const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);
  let backgroundImageLoaded = false;
  const checkAuth = useCheckAuth();
  const history = useHistory();

  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        history.push("/");
      })
      .catch(() => {
        // not authenticated, stay on the login page
      });
  }, [checkAuth, history]);

  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded && containerRef.current) {
      containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
      backgroundImageLoaded = true;
    }
  };

  // Load background image asynchronously to speed up time to interactive
  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image();
      img.onload = updateBackgroundImage;
      img.src = backgroundImage;
    }
  };

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage();
    }
  });

  return (
    <ThemeProvider theme={muiTheme}>
      <Grid
        className={classnames(classes.main, className)}
        {...rest}
        ref={containerRef}
        container
      >
        <Grid item container justifyContent="center" xs={12} md={6}>
          <Card className={classes.card}>
            <CardMedia
              component="img"
              height="140"
              image="/logo192.png"
              classes={{ media: classes.avatar }}
            />
            <LoginForm />
            <Box textAlign="center" m={2}>
              <a
                href="https://jurnalkawruh.id/index.php/kwa"
                target="_blank"
                rel="nofollow noreferrer"
              >
                jurnalkawruh.id
              </a>
            </Box>
          </Card>
        </Grid>
        <Grid
          xs={12}
          md={6}
          container
          justifyContent="center"
          alignItems="center"
          className={classes.content}
        >
          <LoginKonten />
        </Grid>
        {notification ? createElement(notification) : null}
      </Grid>
    </ThemeProvider>
  );
};

Login.propTypes = {
  backgroundImage: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.object,
  staticContext: PropTypes.object,
};

Login.defaultProps = {
  theme: defaultTheme,
  notification: DefaultNotification as any,
};

export default Login;
