import { makeStyles } from "@material-ui/core";
import React from "react";
import {
  SaveButton,
  SimpleForm,
  SimpleFormProps,
  Toolbar,
  ToolbarProps,
} from "react-admin";
import DeleteButtonWithPermissions from "./DeleteButtonWithPermissions";

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const ToolbarWithPermissions = (props: ToolbarProps) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    <DeleteButtonWithPermissions />
  </Toolbar>
);

const SimpleFormWithPermissions = ({
  children,
  ...props
}: SimpleFormProps & {
  children: React.ReactNode;
  disabled?: boolean;
}) => (
  <SimpleForm toolbar={<ToolbarWithPermissions />} {...props}>
    {children}
  </SimpleForm>
);
/*hasAccess(props.permissions, `${props.resource}.delete`) ? (
    <DeleteButton {...props} />
  ) : null;*/

export default SimpleFormWithPermissions;
