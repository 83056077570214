import { fetchUtils, UserIdentity } from "react-admin";
import { apiUrl } from "./dataProvider";

import { AUTH_GET_PERMISSIONS } from "react-admin";

const buildFullAccessFor = (resources: string[]) =>
  resources.reduce((acc, resource) => {
    acc[resource] = {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: true,
      delete: true,
    };

    return acc;
  }, {} as Record<string, any>);

const buildNoDeleteAccessFor = (resources: string[]) =>
  resources.reduce((acc, resource) => {
    acc[resource] = {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: true,
      delete: false,
    };

    return acc;
  }, {} as Record<string, any>);

const permsData = {
  "1000": {
    ...buildFullAccessFor(["users", "kasus", "akuntansi", "proses", "konten"]),
  },
  "500": {
    ...buildNoDeleteAccessFor([
      "users",
      "kasus",
      "akuntansi",
      "proses",
      "konten",
    ]),
  },
  "0": {
    kasus: {
      enabled: true,
      list: true,
      show: true,
    },
    proses: {
      enabled: true,
      list: true,
      show: true,
    },
    konten: {
      list: true,
      show: true,
    },
  },
};

type AuthProvider = {
  login: (params: any) => Promise<any>;
  logout: (params: any) => Promise<void | false | string>;
  checkAuth: (params: any) => Promise<void>;
  checkError: (error: any) => Promise<void>;
  getPermissions: (type: any, params: any) => Promise<any>;
  getIdentity?: () => Promise<UserIdentity>;
  [key: string]: any;
};

const httpClient = fetchUtils.fetchJson;

const authProvider: AuthProvider = {
  // authentication
  login: async ({ username, password }) => {
    const results = await httpClient(`${apiUrl}/auth/login`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      credentials: "same-origin",
    });

    localStorage.setItem("roleId", results.json.role_id);
    return Promise.resolve();
  },
  checkError: (error) =>
    error.status === 401 ? Promise.reject() : Promise.resolve(),
  checkAuth: () =>
    fetch(`${apiUrl}/auth/getUser`, {
      method: "GET",
      credentials: "same-origin",
    }).then((data) => {
      if (data.status === 401) {
        return Promise.reject();
      } else {
        return Promise.resolve(data.json());
      }
    }),
  logout: async () => {
    localStorage.removeItem("roleId");
    await fetch(`${apiUrl}/auth/logout`);
    return Promise.resolve();
  },
  getIdentity: () =>
    fetch(`${apiUrl}/auth/getUser`, {
      method: "GET",
      credentials: "same-origin",
    }).then((data) => data.json()),
  // authorization
  getPermissions: (type, params) => {
    const roleId = localStorage.getItem("roleId") as keyof typeof permsData;
    return Promise.resolve({ ...permsData[roleId], roleId });
  },
};

export default authProvider;
