import React from "react";
import { hasAccess } from "ra-auth-acl";
import { DeleteButton, DeleteButtonProps } from "react-admin";

import { usePermissions } from "react-admin";

const DeleteButtonWithPermissions = (props: DeleteButtonProps & any) => {
  const { permissions } = usePermissions();
  return hasAccess(permissions, `${props.resource}.delete`) ? (
    <DeleteButton {...props} />
  ) : null;
};

export default DeleteButtonWithPermissions;
