import ArticleIcon from "@material-ui/icons/Announcement";
import * as React from "react";
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  ImageField,
  ImageInput,
  List,
  NumberField,
  required,
  TextField,
  TextInput,
} from "react-admin";
import SimpleFormWithPermissions from "../components/SimpleFormWithPermissions";

const filters = [<TextInput label="Search" source="q" alwaysOn />];

const KontenQuickView = (props: any) => <TextField source="isi" />;

const KontenList = (props: any) => (
  <List {...props} filters={filters}>
    <Datagrid expand={<KontenQuickView />}>
      <NumberField source="id" />
      <TextField source="judul" />
      <EditButton />
    </Datagrid>
  </List>
);

const KontenEdit = (props: any) => (
  <Edit {...props}>
    <SimpleFormWithPermissions>
      <TextField source="id" />
      <TextInput source="judul" validate={required()} />
      <ImageInput source="gambar" accept="image/*" maxSize={10000000}>
        <ImageField source="src" />
      </ImageInput>
      <TextInput
        source="isi"
        variant="outlined"
        multiline
        minRows={3}
        validate={required()}
      />
      <DateField source="created_at" showTime={true} />
      <DateField source="updated_at" showTime={true} />
    </SimpleFormWithPermissions>
  </Edit>
);

const KontenCreate = (props: any) => (
  <Create {...props}>
    <SimpleFormWithPermissions>
      <TextInput source="judul" validate={required()} />
      <ImageInput source="gambar" accept="image/*" maxSize={10000000}>
        <ImageField source="src" />
      </ImageInput>
      <TextInput
        source="isi"
        variant="outlined"
        multiline
        minRows={3}
        validate={required()}
      />
    </SimpleFormWithPermissions>
  </Create>
);

export default {
  list: KontenList,
  edit: KontenEdit,
  create: KontenCreate,
  icon: ArticleIcon,
};
