import { Box, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import SyncProblemIcon from "@material-ui/icons/SyncProblem";
import ExtendedImageField from "components/ExtendedImageField";
import { hasAccess } from "ra-auth-acl";
import * as React from "react";
import {
  AutocompleteInput,
  BulkDeleteWithConfirmButton,
  Button,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  FunctionField,
  ImageField,
  ImageInput,
  Link,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  useRefresh,
  CreateButton,
  SimpleShowLayout,
} from "react-admin";
import { Route, useHistory, useLocation, useRouteMatch } from "react-router";
import DeleteButtonWithPermissions from "../components/DeleteButtonWithPermissions";
import SimpleFormWithPermissions from "../components/SimpleFormWithPermissions";

const KasusQuickViewStyles = makeStyles({
  imageField: {
    flexWrap: "wrap",
  },
});

const KasusQuickView = (props: any) => {
  const styles = KasusQuickViewStyles();
  return (
    <React.Fragment>
      <ExtendedImageField
        source="buktiFoto"
        src="src"
        title="title"
        className={styles.imageField}
      />
    </React.Fragment>
  );
};

const filters = [
  <ReferenceInput label="User" source="user_id" reference="users" alwaysOn>
    <AutocompleteInput optionText="displayname" />
  </ReferenceInput>,
];

const KasusList = (props: any) => (
  <List
    {...props}
    bulkActionButtons={
      hasAccess(props.permissions, `${props.resource}.delete`) ? (
        <BulkDeleteWithConfirmButton
          confirmTitle="Bulk Deleting Kasus"
          confirmContent="Are you sure?"
        />
      ) : (
        false
      )
    }
    actions={
      <TopToolbar>
        {hasAccess(props.permissions, `${props.resource}.create`) && (
          <CreateButton />
        )}
      </TopToolbar>
    }
    filters={props.permissions && props.permissions.roleId !== "0" && filters}
  >
    <Datagrid>
      <NumberField source="id" />
      <DateField source="tanggal" />
      <TextField source="nama" label="Nama" />
      {props.permissions &&
        props.permissions.roleId !== "0" && [
          <ReferenceField label="User" source="user_id" reference="users">
            <TextField source="displayname" />
          </ReferenceField>,
          <EditButton />,
        ]}
      <ShowButton />
    </Datagrid>
  </List>
);

const ProsesCreate = (props: any) => {
  const refresh = useRefresh();
  const location = useLocation<any>();
  const history = useHistory();

  const kasusId =
    location.state && location.state.record
      ? location.state.record.kasus_id
      : undefined;

  if (kasusId === undefined) return null;
  const redirect = kasusId !== undefined ? `/kasus/${kasusId}` : false;

  return (
    <Create
      title=" "
      onSuccess={() => {
        refresh();
        if (redirect) history.push(redirect);
      }}
      resource={props.resource}
      basePath={props.basePath}
      actions={props.actions}
    >
      <SimpleForm redirect={redirect}>
        <TextField hidden addLabel={false} source="kasus_id" />
        <TextInput
          source="keterangan"
          multiline
          fullWidth
          validate={required()}
          minRows={3}
          variant="outlined"
        />
        <ImageInput
          source="buktiFoto"
          multiple
          accept="image/*,application/pdf"
          maxSize={10000000}
        >
          <ExtendedImageField />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

const ProsesEdit = (props: any) => {
  const routerMatch = useRouteMatch();

  const kasusId = props.kasusId;

  if (
    !(
      kasusId !== undefined &&
      Number.isInteger(Number.parseInt((routerMatch.params as any).prosesId))
    )
  )
    return null;
  const redirect = kasusId !== undefined ? `/kasus/${kasusId}` : false;

  return (
    <Edit
      title=" "
      resource={props.resource}
      basePath={props.basePath}
      id={(routerMatch.params as any).prosesId}
    >
      <SimpleFormWithPermissions redirect={redirect}>
        <TextField hidden addLabel={false} source="kasus_id" />
        <TextInput
          source="keterangan"
          multiline
          fullWidth
          validate={required()}
          minRows={3}
          variant="outlined"
        />
        <ImageInput
          source="buktiFoto"
          multiple
          accept="image/*,application/pdf"
          maxSize={10000000}
        >
          <ExtendedImageField source="src" title="title" />
        </ImageInput>
      </SimpleFormWithPermissions>
    </Edit>
  );
};

const ProsesGrid = (props: any) => (
  <React.Fragment>
    <Datagrid expand={<KasusQuickView />} {...props}>
      <NumberField source="id" />
      <DateField source="created_at" />
      <TextField source="keterangan" />
      {props.viewOnly
        ? null
        : [
            <FunctionField
              render={(field: any) => (
                <Button
                  component={Link}
                  to={{
                    pathname: `${props.kasusBasePath}/${props.kasusId}/${field.id}`,
                    state: {
                      record: {
                        kasus_id: props.kasusId,
                      },
                    },
                  }}
                  label="Edit"
                >
                  <EditIcon />
                </Button>
              )}
            />,
            <DeleteButtonWithPermissions redirect={false} />,
          ]}
    </Datagrid>
    {props.viewOnly ? null : (
      <Box m={2} textAlign="right">
        <Button
          component={Link}
          to={{
            pathname: `${props.kasusBasePath}/${props.kasusId}/create`,
            state: {
              record: {
                kasus_id: props.kasusId,
              },
            },
          }}
          label="New Proses"
        >
          <AddIcon />
        </Button>
      </Box>
    )}

    <Route
      path={`${props.kasusBasePath}/${props.kasusId}/create`}
      render={() => <ProsesCreate {...props} />}
    />
    <Route
      path={`${props.kasusBasePath}/${props.kasusId}/:prosesId`}
      render={() => <ProsesEdit {...props} />}
    />
  </React.Fragment>
);

const KasusEdit = (props: any) => (
  <Edit {...props}>
    <SimpleFormWithPermissions>
      <TextField source="id" />
      <DateInput source="tanggal" validate={required()} />
      <TextInput validate={required()} label="Nama Kasus" source="nama" />
      <ReferenceInput label="User" source="user_id" reference="users">
        <AutocompleteInput validate={required()} optionText="displayname" />
      </ReferenceInput>
      <ReferenceManyField
        reference="proses"
        target="kasus_id"
        addLabel={false}
        fullWidth
        sort={{ field: "created_at", order: "ASC" }}
      >
        <ProsesGrid
          kasusId={props.id}
          kasusBasePath={props.basePath}
          permissions={props.permissions}
        />
      </ReferenceManyField>
      <DateField source="created_at" showTime={true} />
      <DateField source="updated_at" showTime={true} />
    </SimpleFormWithPermissions>
  </Edit>
);

const KasusCreate = (props: any) => (
  <Create {...props}>
    <SimpleFormWithPermissions>
      <DateInput
        source="tanggal"
        validate={required()}
        defaultValue={Date.now()}
      />
      <TextInput validate={required()} label="Nama Kasus" source="nama" />
      <ReferenceInput
        validate={required()}
        label="User"
        source="user_id"
        reference="users"
      >
        <AutocompleteInput validate={required()} optionText="displayname" />
      </ReferenceInput>
    </SimpleFormWithPermissions>
  </Create>
);

const KasusShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <NumberField source="id" />
      <DateField source="tanggal" />
      <TextField source="nama" />
      <DateField source="created_at" showTime={true} />
      <DateField source="updated_at" showTime={true} />
      <ReferenceManyField
        reference="proses"
        target="kasus_id"
        addLabel={false}
        fullWidth
        sort={{ field: "created_at", order: "ASC" }}
      >
        <ProsesGrid
          kasusId={props.id}
          kasusBasePath={props.basePath}
          viewOnly={true}
        />
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

export default {
  list: KasusList,
  edit: KasusEdit,
  create: KasusCreate,
  show: KasusShow,
  icon: SyncProblemIcon,
};
