import simpleRestProvider from "ra-data-simple-rest";
import isPlainObject from "lodash.isplainobject";
import { DataProvider, fetchUtils } from "react-admin";

const httpClient = fetchUtils.fetchJson;
export const apiUrl = ".";

const dataProvider = simpleRestProvider(apiUrl, httpClient, "X-Total-Count");

const containFile = (element: any) =>
  isPlainObject(element) &&
  Object.values(element).some((value) => value instanceof File);

const transformReactAdminDataToRequestBody = (data: any = {}) => {
  const values = Object.values(data);

  // first check: shallow file upload check
  // second check: check arrays if contains files
  if (
    !values.some(
      (value) =>
        containFile(value) ||
        (Array.isArray(value) && value.some((file) => "rawFile" in file))
    )
  ) {
    return JSON.stringify(data);
  }

  // If data contains a file, use FormData instead of JSON.
  const body = new FormData();
  Object.entries(data).forEach((data) => {
    const [key, value]: [string, any] = data;
    // React-Admin FileInput format is an object containing a file.
    if (containFile(value)) {
      return body.append(
        key,
        Object.values(value).find((value) => value instanceof File) as Blob
      );
    }

    // check for file arrays.
    if (Array.isArray(value) && value.length > 0) {
      // try checking if it's a file array
      if (value.some((file) => "rawFile" in file)) {
        const unmodifiedFiles: any[] = [];
        value.forEach((file) => {
          if ("rawFile" in file) {
            body.append(`${key}[]`, file.rawFile as Blob);
          } else {
            unmodifiedFiles.push(file);
          }
        });
        body.append(key, JSON.stringify(unmodifiedFiles));
        return;
      }
    }
    // causing errors.
    /* if ("toJSON" in value && "function" === typeof value.toJSON) {
      return body.append(key, value.toJSON());
    }*/
    if (isPlainObject(value) || Array.isArray(value)) {
      return body.append(key, JSON.stringify(value));
    }
    return body.append(key, value);
  });

  return body;
};

const customDataProvider: DataProvider = {
  ...dataProvider,
  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: transformReactAdminDataToRequestBody(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),
  update: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/update/${params.id}`, {
      method: "POST",
      body: transformReactAdminDataToRequestBody(params.data),
    }).then(({ json }) => ({ data: json }));
  },
};

export default customDataProvider;
