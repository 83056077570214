import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import RichTextInput from "ra-input-rich-text";
import * as React from "react";
import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  List,
  NumberField,
  NumberInput,
  required,
  RichTextField,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  downloadCSV,
} from "react-admin";
import SimpleFormWithPermissions from "../components/SimpleFormWithPermissions";

import jsonExport from "jsonexport/dist";

const akuntansiJenis = {
  "0": "Pemasukan",
  "1": "Pengeluaran",
};

const filters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <DateInput label="Tanggal Mulai" source="startDate" />,
  <DateInput label="Tanggal Selesai" source="endDate" />,
];

const exporter = (data: any) => {
  const dataForExport = data.map((datum: any) => {
    datum.jenis =
      datum.jenis in akuntansiJenis
        ? akuntansiJenis[datum.jenis as keyof typeof akuntansiJenis]
        : "?";
    return datum;
  });
  jsonExport(dataForExport, {}, (err, csv) => {
    downloadCSV(csv, "akuntansi"); // download as 'posts.csv` file
  });
};

const AkuntansiQuickView = (props: any) => <TextField source="keterangan" />;

const AkuntansiList = (props: any) => (
  <List {...props} exporter={exporter} filters={filters}>
    <Datagrid expand={<AkuntansiQuickView />}>
      <NumberField source="id" />
      <DateField source="tanggal" />
      <SelectField
        source="jenis"
        choices={[
          { id: "0", name: "Pemasukan" },
          { id: "1", name: "Pengeluaran" },
        ]}
      />
      <TextField source="nama" />
      <NumberField source="jumlah" />
      <EditButton />
    </Datagrid>
  </List>
);

const AkuntansiEdit = (props: any) => (
  <Edit {...props}>
    <SimpleFormWithPermissions>
      <TextField source="id" />
      <DateInput source="tanggal" validate={required()} />
      <SelectInput
        source="jenis"
        choices={[
          { id: "0", name: "Pemasukan" },
          { id: "1", name: "Pengeluaran" },
        ]}
        validate={required()}
      />
      <TextInput source="nama" validate={required()} />
      <NumberInput source="jumlah" validate={required()} />
      <TextInput
        source="keterangan"
        validate={required()}
        multiline
        minRows={3}
        variant="outlined"
      />
      <DateField source="created_at" showTime={true} />
      <DateField source="updated_at" showTime={true} />
    </SimpleFormWithPermissions>
  </Edit>
);

const AkuntansiCreate = (props: any) => (
  <Create {...props}>
    <SimpleFormWithPermissions>
      <DateInput
        source="tanggal"
        defaultValue={Date.now()}
        validate={required()}
      />
      <SelectInput
        source="jenis"
        choices={[
          { id: "0", name: "Pemasukan" },
          { id: "1", name: "Pengeluaran" },
        ]}
        validate={required()}
      />
      <TextInput source="nama" validate={required()} />
      <NumberInput source="jumlah" validate={required()} />
      <TextInput
        source="keterangan"
        validate={required()}
        multiline
        minRows={3}
        variant="outlined"
      />
    </SimpleFormWithPermissions>
  </Create>
);

export default {
  list: AkuntansiList,
  edit: AkuntansiEdit,
  create: AkuntansiCreate,
  icon: AttachMoneyIcon,
};
