import PeopleIcon from "@material-ui/icons/People";
import * as React from "react";
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  FunctionField,
  List,
  required,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  usePermissions,
} from "react-admin";
import SimpleFormWithPermissions from "../components/SimpleFormWithPermissions";

const UserFilters = [<TextInput label="Search" source="q" alwaysOn />];

const UserList = (props: any) => {
  const rolePerms = usePermissions();
  return (
    <List {...props} filters={UserFilters}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="displayname" label="Display Name" />
        <TextField source="username" />
        <SelectField
          choices={[
            { id: "0", name: "User" },
            { id: "500", name: "Admin" },
            { id: "1000", name: "Super Admin" },
          ]}
          source="role_id"
        />
        <FunctionField
          render={(data: any) => (
            <EditButton
              disabled={
                rolePerms.permissions &&
                Number.parseInt(data.role_id) >
                  Number.parseInt(rolePerms.permissions.roleId)
              }
              record={data}
              {...props}
            />
          )}
        />
      </Datagrid>
    </List>
  );
};

const RoleChoicesWithPermissions = (props: any) => {
  const rolePerms = usePermissions();
  const choices = [
    { id: "0", name: "User" },
    { id: "500", name: "Admin" },
    { id: "1000", name: "Super Admin" },
  ].filter((choice) => {
    return (
      rolePerms.permissions &&
      Number.parseInt(rolePerms.permissions.roleId) >=
        Number.parseInt(choice.id)
    );
  });
  return (
    <SelectInput
      choices={choices}
      disabled={rolePerms.loading}
      source="role_id"
      validate={required()}
      {...props}
    />
  );
};

const UserEdit = (props: any) => (
  <Edit {...props}>
    <SimpleFormWithPermissions>
      <TextField source="id" />
      <TextInput source="displayname" validate={required()} />
      <TextInput source="username" validate={required()} />
      <TextInput
        source="password"
        type="password"
        helperText="Fill in to change"
      />
      <RoleChoicesWithPermissions />
      <DateField source="created_at" showTime={true} />
      <DateField source="updated_at" showTime={true} />
    </SimpleFormWithPermissions>
  </Edit>
);

const UserCreate = (props: any) => (
  <Create {...props}>
    <SimpleFormWithPermissions>
      <TextInput source="displayname" validate={required()} />
      <TextInput source="username" validate={required()} />
      <TextInput source="password" type="password" validate={required()} />
      <RoleChoicesWithPermissions />
    </SimpleFormWithPermissions>
  </Create>
);

export default {
  list: UserList,
  edit: UserEdit,
  create: UserCreate,
  icon: PeopleIcon,
};
