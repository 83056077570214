import * as React from "react";
import { Admin, Resource, useAuthenticated } from "react-admin";
import customDataProvider from "./lib/dataProvider";
import authProvider from "./lib/authProvider";
import { ResourceWithPermissions } from "ra-auth-acl";

import Users from "./resources/Users";
import Kasus from "./resources/Kasus";
import Akuntansi from "./resources/Akuntansi";
import Login from "./components/auth/Login";
import Konten from "resources/Konten";

const AuthTest = () => {
  useAuthenticated();
  return null;
};

const App = () => (
  <Admin
    dataProvider={customDataProvider}
    authProvider={authProvider as any}
    loginPage={Login}
  >
    {(permissions) => [
      <ResourceWithPermissions
        name="kasus"
        options={{ label: "Kasus" }}
        permissions={permissions}
        {...Kasus}
      />,
      <ResourceWithPermissions
        name="users"
        permissions={permissions}
        {...Users}
      />,
      <ResourceWithPermissions
        name="konten"
        options={{ label: "Konten" }}
        permissions={permissions}
        {...Konten}
      />,
      <ResourceWithPermissions
        name="akuntansi"
        options={{ label: "Akuntansi" }}
        permissions={permissions}
        {...Akuntansi}
      />,
      <ResourceWithPermissions name="proses" permissions={permissions} />,
      <AuthTest />,
    ]}
  </Admin>
);

export default App;
